<template>
    <div class="Home">
        <!-- <h1>主页</h1> -->
        <div class="shuffling">
            <el-carousel ref="nop" indicator-position="none">
                <el-carousel-item v-for="item in home_img" :key="item.id">
                    <div @touchstart="start($event)" @touchmove="move($event)">
                        <img :src="item" alt="">
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="content">
            <div class="positioning">
                <img src="https://img.youstarplanet.com/h5/home/positioning.png" alt="">
                <div>
                    <span>定位</span>
                    <span>共建共享中国演艺文化数字产业新格局</span>
                </div>
            </div>
            <div class="value">
                <img src="https://img.youstarplanet.com/h5/home/value.png" alt="">
                <div>
                    <span>价值</span>
                    <span>以产业链优化供应链</span>
                    <span>以数据链赋能价值链</span>
                    <span>以创新链升级内容链</span>
                    <span>以生态链激活保障链</span>
                </div>
            </div>
            <div class="mission">
                <div class="mission_img">
                    <el-carousel ref="nops">
                        <el-carousel-item v-for="item in mission_img" :key="item.id">
                            <div @touchstart="start1($event)" @touchmove="move1($event)">
                                <img :src="item" alt="">
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="mission_text">
                    <span>使命</span>
                    <span>举旗帜、聚民心、育新人、兴文化、展形象</span>
                </div>
            </div>
            <div class="vision">
                <img src="https://img.youstarplanet.com/h5/home/vision.png" alt="">
                <div>
                    <span>愿景</span>
                    <span>让世界倾听中国声音</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    data() {
        return {
            home_img:[
                // require("https://img.youstarplanet.com/h5/home/shuffling1.png"),
                "https://img.youstarplanet.com/h5/home/shuffling2.png",
                "https://img.youstarplanet.com/h5/home/shuffling3.png",
            ], 
            mission_img:[
                "https://img.youstarplanet.com/h5/home/mission1.png",
                "https://img.youstarplanet.com/h5/home/mission2.png",
                "https://img.youstarplanet.com/h5/home/mission3.png",
                "https://img.youstarplanet.com/h5/home/mission4.png",
                "https://img.youstarplanet.com/h5/home/mission5.png",
            ], 
            carousel:0, //轮播图高度
            startX: '',
            startY: '',
            moveY: '',
            moveX: '',
            startTime: '',
        }
    },
    async mounted() {
        //页面跳转默认顶部
        document.documentElement.scrollTop = 0
    },
    created() {
        // 获取到轮播图的初始值
        setTimeout(() => {
            this.carousel = window.innerWidth*80/192
        }, 0); 
    },
    methods:{
        start(e) {
            this.startX = e.touches[0].clientX;
            this.startY = e.touches[0].clientY;
        },
        move(e) {
            this.moveX = e.touches[0].clientX;
            this.moveY = e.touches[0].clientY;
            var nowtime = new Date().getTime();
            if (this.startTime == undefined || nowtime > this.startTime) {
                if (this.startX - this.moveX <= 0) { // 右滑触发
                    this.prev();
                    return false;
                } else {
                    this.next();
                    return false;
                }
            }
        },
        prev() {
            this.$refs.nop.prev();
            this.startTime = new Date().getTime() + 500;
        },
        next() {
            this.$refs.nop.next();
            this.startTime = new Date().getTime() + 500;
        },
        start1(e) {
            this.startX = e.touches[0].clientX;
            this.startY = e.touches[0].clientY;
        },
        move1(e) {
            this.moveX = e.touches[0].clientX;
            this.moveY = e.touches[0].clientY;
            var nowtime = new Date().getTime();
            if (this.startTime == undefined || nowtime > this.startTime) {
                if (this.startX - this.moveX <= 0) { // 右滑触发
                    this.prev1();
                    return false;
                } else {
                    this.next1();
                    return false;
                }
            }
        },
        prev1() {
            this.$refs.nops.prev();
            this.startTime = new Date().getTime() + 500;
        },
        next1() {
            this.$refs.nops.next();
            this.startTime = new Date().getTime() + 500;
        },
    }
}
</script>

<style lang="less">
.Home{
    padding: 18px 14px 40px;
    .shuffling{
        width: 100%;
        .el-carousel__container{
            height: 440px;
            width: 100%;
            object-fit:cover;
        }
        .el-carousel__item.is-animating{
            text-align: center;
        }
        .el-carousel__item {
            img{
                width: 100%;
                object-fit:cover;
            }
        }
    }
    .content{
        margin-top: 27px;
        .positioning{
            margin: 0 auto;
            position: relative;
            margin-bottom: 27px;
            img{
                width: 100%;
                object-fit:cover;
            }
            &>div{
                position: absolute;
                top: 108px;
                left: 26px;
                span{
                    display: block;
                    font-size: 15px;
                    font-weight: 500;
                    color: #FFFFFF;
                    &:nth-child(1){
                        font-size: 40px;
                        font-weight: 400;
                        margin-bottom: 23px;
                    }
                }
            }
        }
        .value{
            position: relative;
            margin-bottom: 27px;
            img{
                width: 100%;
                object-fit:cover;
            }
            &>div{
                position: absolute;
                top: 108px;
                left: 26px;
                span{
                    display: block;
                    font-size: 15px;
                    font-weight: 500;
                    color: #FFFFFF;
                    margin-bottom: 10px;
                    &:nth-child(1){
                        font-size: 40px;
                        font-weight: 400;
                        margin-bottom: 23px;
                    }
                }
            }
        }
        .mission{
            margin: 0 auto;
            margin-bottom: 27px;
            position: relative;
            .mission_img{
                width: 100%;
                .el-carousel__container{
                    width: 100%;
                    object-fit:cover;
                    height: 521px !important;
                }
                .el-carousel__item.is-animating{
                    text-align: center;
                }
                .el-carousel__item {
                    img{
                        width: 100%;
                        object-fit:cover;
                    }
                }
                .el-carousel__indicators--horizontal {
                    width: 120px;
                    left: 80%;
                    .el-carousel__button{
                        width: 14px;
                        border-radius: 50%;
                    }
                }
            }
            .mission_text{
                position: absolute;
                bottom: 28px;
                left: 26px;
                z-index: 999;
                span{
                    display: block;
                    font-size: 15px;
                    font-weight: 500;
                    color: #FFFFFF;
                    margin-bottom: 10px;
                    &:nth-child(1){
                        font-size: 40px;
                        font-weight: 400;
                        margin-bottom: 4px;
                    }
                }
            }
        }
        .vision{
            position: relative;
            img{
                width: 100%;
                object-fit:cover;
            }
            &>div{
                position: absolute;
                top: 108px;
                left: 26px;
                span{
                    display: block;
                    font-size: 15px;
                    font-weight: 500;
                    color: #FFFFFF;
                    margin-bottom: 10px;
                    &:nth-child(1){
                        font-size: 40px;
                        font-weight: 400;
                        margin-bottom: 23px;
                    }
                }
            }
        }
    }
}
</style>